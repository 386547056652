import { useEffect, useState } from 'react';
import { areas } from '../../constants';
import { City } from '../../types';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { getServingAreasLinks } from './helpers/getServingAreas';
import { quantity } from './constants/cityQuantity';
import { AreaItem } from './ui/AreaItem/AreaItem';

import './styles/WhereWeWork.styles.scss';

export const WhereWeWork = () => {
  const [areaItems, setAreaItems] = useState<City[]>([]);

  useEffect(() => {
    const servingAreas = getServingAreasLinks(areas, quantity);
    setAreaItems(servingAreas);
  }, []);

  return (
    <section className="where-we-work">
      <Container>
        <div className="where-we-work">
          <h2 className="where-we-work__title">
            Where are you looking for cleaning services?
          </h2>
          <div className="where-we-work__links">
            <ul className="where-we-work__list">
              {areaItems.map((area) => {
                return <AreaItem key={area.path} area={area} />;
              })}
            </ul>
          </div>
        </div>
      </Container>
    </section>
  );
};
