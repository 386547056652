import React from 'react';
import { Link } from 'react-router-dom';
import { BurgerTextType } from '../../types/ButtonTextType';

import './styles/HeaderButton.styles.scss';

interface ButtonProps {
  text: BurgerTextType;
}

export const HeaderButton: React.FC<ButtonProps> = ({ text }) => {
  return (
    <Link to="/" className={`header__button buttons__${text}`}>
      {text}
    </Link>
  );
};
