import { Routes } from '../types';
import { HomePage, NotFoundPage } from '../pages';

export const routes: Routes = {
  homeRoute: { name: '', path: '', element: <HomePage /> },
  navRoutes: [
    { name: 'Services', path: 'cleaning-services', element: <NotFoundPage /> },
    { name: 'Gift Cards', path: 'gift-cards', element: <NotFoundPage /> },
    { name: 'Areas', path: 'locations', element: <NotFoundPage /> },
    { name: 'Contact Us', path: 'contact-us', element: <NotFoundPage /> },
  ],
  servicesRoutes: [
    {
      name: 'Our Cleaning Checklist',
      path: 'checklist',
      element: <NotFoundPage />,
    },
    {
      name: 'House Cleaning',
      path: 'cleaning-service',
      element: <NotFoundPage />,
    },
    {
      name: 'Condo and Apartment Cleaning',
      path: 'condo-and-apartment-cleaning-services',
      element: <NotFoundPage />,
    },
    {
      name: 'Commercial Cleaning',
      path: 'commercial-office-cleaning',
      element: <NotFoundPage />,
    },
    {
      name: 'Deep cleaning service',
      path: 'deep-cleaning',
      element: <NotFoundPage />,
    },
    {
      name: 'Move in Move out cleaning',
      path: 'move-in-move-out-cleaning',
      element: <NotFoundPage />,
    },
    {
      name: 'Green and Eco-Friendly Cleaning',
      path: 'green-and-eco-friendly-cleaning-service',
      element: <NotFoundPage />,
    },
    {
      name: 'Post Renovation Cleaning',
      path: 'post-renovation-cleaning',
      element: <NotFoundPage />,
    },
    {
      name: 'AirBnB Cleaning',
      path: 'airbnb-cleaning',
      element: <NotFoundPage />,
    },
    {
      name: 'Cleaning Services for Seniors',
      path: 'cleaning-services-for-seniors',
      element: <NotFoundPage />,
    },
    {
      name: 'Spring Cleaning',
      path: 'spring-cleaning-services',
      element: <NotFoundPage />,
    },
  ],
  partnershipsRoute: {
    name: 'Partnerships',
    path: 'our-partners',
    element: <NotFoundPage />,
  },
  sholarshipsRoute: {
    name: 'Scholarships',
    path: 'scholarship',
    element: <NotFoundPage />,
  },
};
