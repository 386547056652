import React from 'react';
import { Route } from '../../../../types';
import { breakpoints } from '../../../../constants';
import { LinkWithDropdown } from '../LinkWithDropdown/LinkWithDropdown';
import { DropdownMenu } from '../DropdownMenu/DropDownMenu';
import { useWidthWindow } from '../../../../hooks/useWidthWindows';

interface ServicesLinkWithDropdownProps {
  route: Route;
  setIsDropdownShown: React.Dispatch<React.SetStateAction<boolean>>;
  isDropdownShown: boolean;
}

export const ServicesLinkWithDropdown: React.FC<
  ServicesLinkWithDropdownProps
> = ({ route, setIsDropdownShown, isDropdownShown }) => {
  const { windowWidth } = useWidthWindow();

  return (
    <>
      <li
        className="menu__item menu__dropdown"
        onMouseEnter={() => setIsDropdownShown(true)}
        onMouseLeave={() => setIsDropdownShown(false)}
      >
        <LinkWithDropdown
          route={route}
          setIsDropdownShown={setIsDropdownShown}
          isDropdownShown={isDropdownShown}
        />
        {isDropdownShown && windowWidth >= breakpoints.desktop && (
          <DropdownMenu />
        )}
      </li>
      {isDropdownShown && windowWidth < breakpoints.desktop && <DropdownMenu />}
    </>
  );
};
