import { Services } from '../types';

export const services: Services[] = [
  {
    name: 'Cleaning Checklist',
    path: 'checklist',
  },
  {
    name: 'House Cleaning',
    path: 'cleaning-service',
  },
  {
    name: 'Condo and Apartment Cleaning',
    path: 'condo-and-apartment-cleaning-services',
  },
  {
    name: 'Deep Cleaning Service',
    path: 'deep-cleaning',
  },
  {
    name: 'Move In Move Out Cleaning',
    path: 'move-in-move-out-cleaning',
  },
  {
    name: 'Post Renovation Cleaning',
    path: 'post-renovation-cleaning',
  },
  {
    name: 'Green and Eco-Friendly Cleaning',
    path: 'green-and-eco-friendly-cleaning-service',
  },
  {
    name: 'AirBnB Cleaning',
    path: 'airbnb-cleaning',
  },
  {
    name: 'Commercial Cleaning',
    path: 'commercial-office-cleaning',
  },
  {
    name: 'Cleaning Services for Seniors',
    path: 'cleaning-services-for-seniors',
  },
  {
    name: 'Spring Cleaning',
    path: 'spring-cleaning-services',
  },
];
