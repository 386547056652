import { Areas, City } from '../../../types';
import { additionalText } from '../constants/addtionalText';

export const getServingAreasLinks = (areas: Areas[], quantity: number) => {
  const servingAreas: City[] = [];
  const { cities } = areas[0];
  const iterations = Math.min(quantity, cities.length);
  const allLocationsLink = {
    name: additionalText.allLocations,
    path: '/',
  };

  for (let i = 0; i < iterations; i++) {
    const { name, path } = cities[i];

    const city: City = {
      name: `${name} ${additionalText.cleaningService}`,
      path,
    };

    servingAreas.push(city);
  }

  servingAreas.push(allLocationsLink);

  return servingAreas;
};
