import React from 'react';
import { NavLink } from 'react-router-dom';
import { Route } from '../../../../types';
import { ArrowDownIcon } from './ui/ArrowDownIcon';

import './styles/LinkWithDropdown.styles.scss';

interface LinkWithDropdownProps {
  route: Route;
  isDropdownShown: boolean;
  setIsDropdownShown: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LinkWithDropdown: React.FC<LinkWithDropdownProps> = ({
  route,
  setIsDropdownShown,
  isDropdownShown,
}) => {
  return (
    <>
      <NavLink className="menu__link dropdown-link" to={route.path}>
        {route.name}
      </NavLink>
      <button
        className="dropdown-link__toggler"
        onClick={() => setIsDropdownShown(!isDropdownShown)}
      >
        <ArrowDownIcon />
      </button>
    </>
  );
};
