import { Link } from 'react-router-dom';
import { routes } from '../../../../constants';

import logo from '../../../../assets/header/logoTransparent86x86.png';
import './styles/HeaderLogo.styles.scss';

export const HeaderLogo = () => {
  const { homeRoute } = routes;

  return (
    <Link className="logo" to={homeRoute.path}>
      <img className="logo__image" src={logo} alt="Crystal Clear logo" />
    </Link>
  );
};
