import { PhoneIcon } from './ui/PhoneIcon';

import './styles/PhoneButton.styles.scss';

export const PhoneButton = () => {
  return (
    <a href="tel:+6479169276" className="phone">
      <PhoneIcon />
    </a>
  );
};
