import { Areas } from '../types';

export const areas: Areas[] = [
  {
    name: 'Ontario',
    cities: [
      {
        name: 'Mississauga',
        path: 'mississauga',
      },
      {
        name: 'Brampton',
        path: 'brampton',
      },
      {
        name: 'Caledon',
        path: 'caledon',
      },
      {
        name: 'Vaughan',
        path: 'vaughan',
      },
      {
        name: 'Richmond Hill',
        path: 'richmond-hill',
      },
      {
        name: 'Markham',
        path: 'markham',
      },
      {
        name: 'Aurura',
        path: 'aurura',
      },
      {
        name: 'Newmarket',
        path: 'newmarket',
      },
      {
        name: 'King',
        path: 'king',
      },
      {
        name: 'East Gwillimbury',
        path: 'east-gwillimbury',
      },
      {
        name: 'Stouffville',
        path: 'stouffville',
      },
      {
        name: 'Georgina',
        path: 'georgina',
      },
      {
        name: 'Pickering',
        path: 'pickering',
      },
      {
        name: 'Ajax',
        path: 'ajax',
      },
      {
        name: 'Whitby',
        path: 'whitby',
      },
      {
        name: 'Oshawa',
        path: 'oshawa',
      },
      {
        name: 'Uxbridge',
        path: 'uxbridge',
      },
      {
        name: 'Oakville',
        path: 'oakville',
      },
      {
        name: 'Burlington',
        path: 'burlington',
      },
      {
        name: 'Milton',
        path: 'milton',
      },
      {
        name: 'Halton Hills',
        path: 'halton-hills',
      },
    ],
  },
];
