import React from 'react';
import { NavLink } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { areas, helpfulLinks, services } from '../../constants';
import {
  SvgIconFacebook,
  SvgIconLinkedin,
  SvgIconInstagram,
  SvgIconTikTok,
  SvgIconYouTube,
} from '../../ui/SvgIcon';
import './styles/FooterModule.scss';
import logo from '../../assets/logo120х120.png';
import ccs from '../../assets/footer/CCS.png';
import livingWage from '../../assets/footer/living_wage.png';
import lw from '../../assets/footer/LW.png';
import olwn from '../../assets/footer/OLWN.png';

export const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <ContainerComponent>
        <div className="footer__nav">
          <div className="nav__areas">
            <NavLink className="nav__areas__title" to="/">
              Areas we serve
            </NavLink>
            {areas.map((province) => (
              <div key={province.name} className="nav__area">
                <h3 className="nav__area__province">{province.name}</h3>
                <div className="nav__area__wrapper">
                  {province.cities.map((city) => (
                    <div key={city.name}>
                      <NavLink className="nav__area__city" to="/">
                        {city.name}
                      </NavLink>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="nav__services">
            <h2 className="nav__services__title">Services</h2>
            <div className="nav__services__wrapper">
              {services.map((service) => (
                <NavLink
                  className="nav__services__item"
                  key={service.name}
                  to="/"
                >
                  {service.name}
                </NavLink>
              ))}
            </div>
          </div>
          <div className="nav__help-links">
            <h2 className="nav__help-links__title">Helpful Links</h2>
            <div className="nav__help-links__wrapper">
              {helpfulLinks.map((link) => (
                <NavLink
                  className="nav__help-links__item"
                  key={link.name}
                  to="/"
                >
                  {link.name}
                </NavLink>
              ))}
            </div>
          </div>
          <div className="nav__touch">
            <h2 className="nav__touch__title">Get in Touch</h2>
            <button className="nav__touch_call">
              <a href="tel:6479169276">6-479-169-276</a>
            </button>
            <button className="nav__touch_email">
              <a href="mailto:info@crystalclear.art">info@crystalclear.art</a>
            </button>
            <NavLink className="nav__touch_book" to="/">
              Book Online
            </NavLink>
            <NavLink className="nav__touch_cards" to="/">
              Gift Cards 🎁
            </NavLink>
            <NavLink className="nav__touch_review" to="/">
              Our Reviews
            </NavLink>
          </div>
        </div>
        <div className="footer__container">
          <div className="logo">
            <NavLink to="/">
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
          <div className="mission-statements">
            <p className="statement">1. Delighting Homeowners </p>
            <p className="statement">2. Supporting Communities</p>
            <p className="statement">3. Empowering Cleaners</p>
          </div>
          <div className="social-links">
            <a href="/">
              <SvgIconInstagram />
            </a>
            <a href="https://www.linkedin.com/company/crystal-clear-artistry">
              <SvgIconLinkedin />
            </a>
            <a href="https://www.facebook.com/people/Crystal-Clear/61566571511324/">
              <SvgIconFacebook />
            </a>
            <a href="https://www.youtube.com/channel/UCDiR0hiRE6hXQmqjeGKel0w">
              <SvgIconYouTube />
            </a>
            <a href="/">
              <SvgIconTikTok />
            </a>
          </div>
          <div className="partners-links">
            <a href="/">
              <img alt="partners-links" src={olwn} />
            </a>
            <a href="/">
              <img alt="partners-links" src={livingWage} />
            </a>
            <a href="/">
              <img alt="partners-links" src={ccs} />
            </a>
            <a href="/">
              <img alt="partners-links" src={lw} />
            </a>
          </div>
          <div className="footer-signature">
            <p className="footer-signature__item">
              Copyright © 2024 Crystal Clear Inc - All Rights Reserved -&nbsp;
              <NavLink className="footer-signature__item" to="/">
                Terms and Conditions&nbsp;
              </NavLink>
              -&nbsp;
              <NavLink className="footer-signature__item" to="/">
                Privacy Policy&nbsp;
              </NavLink>
              - Proudly Canadian&nbsp;
              <span>🇨🇦</span>
            </p>
          </div>
        </div>
      </ContainerComponent>
    </footer>
  );
};
