import { useState } from 'react';

import { Fragment } from 'react/jsx-runtime';
import { routes } from '../../../../constants';

import { MenuItem } from '../MenuItem/MenuItem';
import { ServicesLinkWithDropdown } from '../ServicesLinkWithDropDown/ServicesLinkWithDropdown';

import './styles/HeaderMenu.styles.scss';

export const HeaderMenu = () => {
  const [isDropdownShown, setIsDropdownShown] = useState<boolean>(false);

  const { navRoutes } = routes;
  const servicesRoute =
    navRoutes.find((navRoute) => navRoute.path === 'cleaning-services') || null;

  return (
    <nav className="menu">
      <ul className="menu__list">
        {navRoutes.map((route) => {
          const isServicesLink = servicesRoute?.path === route.path;

          return (
            <Fragment key={route.path}>
              {isServicesLink ? (
                <ServicesLinkWithDropdown
                  route={route}
                  isDropdownShown={isDropdownShown}
                  setIsDropdownShown={setIsDropdownShown}
                />
              ) : (
                <MenuItem route={route} type="menu" />
              )}
            </Fragment>
          );
        })}
      </ul>
    </nav>
  );
};
