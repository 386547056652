import React from 'react';
import { NavLink } from 'react-router-dom';
import { Route } from '../../../../types';
import { MenuItemType } from '../../types/MenuItemType';

import './styles/MenuItem.styles.scss';

interface MenuItemProps {
  route: Route;
  type: MenuItemType;
}

export const MenuItem: React.FC<MenuItemProps> = ({ route, type }) => {
  return (
    <li className={`${type}__item`}>
      <NavLink className={`${type}__link`} to={route.path}>
        {route.name}
      </NavLink>
    </li>
  );
};
