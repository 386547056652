import React from 'react';
import { Link } from 'react-router-dom';
import { City } from '../../../../types';

import './styles/AreaItem.styles.scss';

interface AreaItemProps {
  area: City;
}

export const AreaItem: React.FC<AreaItemProps> = ({ area }) => {
  return (
    <li className="where-we-work__item" key={area.path}>
      <Link to="/" className="where-we-work__link">
        {area.name}
      </Link>
    </li>
  );
};
