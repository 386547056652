import { Outlet } from 'react-router-dom';
import { HeaderModule } from './modules/HeaderModule';
import { Footer } from './modules/Footer';
import { HomePage } from './pages';

const App = () => {
  return (
    <>
      <HeaderModule />
      <HomePage />
      <Footer />
    </>
  );
};

export default App;
