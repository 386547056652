import { routes } from '../../../../constants';
import { MenuItem } from '../MenuItem/MenuItem';

import './styles/DropDownMenu.styles.scss';

export const DropdownMenu = () => {
  const { servicesRoutes } = routes;

  return (
    <div className="dropdown">
      <ul className="dropdown__list">
        {servicesRoutes.map((route) => {
          return <MenuItem key={route.path} route={route} type="dropdown" />;
        })}
      </ul>
    </div>
  );
};
