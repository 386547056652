import { useEffect, useState } from 'react';
import { HeaderButtons } from './components/HeaderButtons/HeaderButtons';
import { HeaderMenu } from './components/HeaderMenu/HeaderMenu';
import { HeaderSideMenu } from './components/HeaderSideMenu/HeaderSideMenu';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { HeaderLogo } from './components/HeaderLogo/HeaderLogo';
import { breakpoints } from '../../constants';
import { useWidthWindow } from '../../hooks/useWidthWindows';

import './styles/HeaderModule.styles.scss';

export const HeaderModule = () => {
  const { windowWidth } = useWidthWindow();
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);

  return (
    <header className="header">
      <Container>
        <div className="header__wrapper">
          <HeaderLogo />
          {windowWidth >= breakpoints.desktop && <HeaderMenu />}
          <HeaderButtons
            isSideMenuOpen={isSideMenuOpen}
            setIsSideMenuOpen={setIsSideMenuOpen}
          />
        </div>
      </Container>
      {isSideMenuOpen && <HeaderSideMenu />}
    </header>
  );
};
